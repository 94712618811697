<template>
  <div>
    <NavTools />
    <div id="login" v-cloak>
      <Alert />
      <Spinner />
      <div class="container" style="width: 400px" v-if="apiDown">
        <center>
          <h3 class="bookingHeader">System upgrade in progress, please try again in 5 minutes.</h3>
        </center>
      </div>
      <div class="container" style="width: 400px" v-if="!apiDown">
        <div>
          <div id="bookingHeaderLocation">
            <center>
              <h3 class="bookingHeader">Log In</h3>
            </center>
          </div>
          <div class="row">
            <h5 class="accountFormHeaderBlue">RETURNING USERS</h5>
            <div class="col-xs-12" v-show="view == 'login'">
              <br />
              <form class="form-horizontal">
                <div class="form-group">
                  <label for="inputEmail3" class="col-xs-4 control-label"
                    >Email</label
                  >
                  <div class="col-xs-8">
                    <input
                      type="email"
                      class="form-control"
                      id="inputEmail3"
                      placeholder="student@example.com"
                      v-model="loginemail"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputPassword3" class="col-xs-4 control-label"
                    >Password</label
                  >
                  <div class="col-xs-8">
                    <input
                      type="password"
                      class="form-control"
                      id="inputPassword3"
                      placeholder="Password"
                      v-model="loginpassword"
                    />
                    <a
                      class="pull-right"
                      style="padding-top: 5px"
                      v-on:click.prevent="user_forgot_password()"
                      >Forgot my Password</a
                    >
                  </div>
                </div>
                <div class="form-group">
                  <center>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-on:click.prevent="user_login()"
                    >
                      CONTINUE
                    </button>
                  </center>
                </div>
              </form>
            </div>
            <div class="col-xs-12" v-show="view == 'check_for_lt'">
              To continue, please check your email for a link to verify your
              login.
            </div>
            <div class="col-xs-12" style="margin-top: 35px">
              <div class="panel panel-default">
                <div
                  data-toggle="collapse"
                  href="#collapseOne"
                  class="accountFormHeaderBlue btn btn-orange btn-block"
                  style="background-color: #e96724"
                  role="tab"
                  id="headingOne"
                >
                  <h4 class="panel-title text-center">
                    Don't have an InitialView account yet?
                  </h4>
                </div>
                <div
                  id="collapseOne"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingOne"
                >
                  <div class="panel-body">
                    <div class="text-center" style="padding: 10px">
                      Please email
                      <a href="mailto:gloria@initialview.com"
                        >gloria@initialview.com</a
                      >
                      to set up your account!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of container -->
    </div>
  </div>
</template>

<script>
/*jshint esversion: 6 */
"use strict";

import Vue from "vue";
import { mapState } from 'vuex'
import VueResource from "vue-resource";
Vue.use(VueResource);

import {
  ivAlertMixin,
  ivSpinnerMixin,
  ivUserAccountMixin,
  getParameterByName,
} from "@/mixins/base-components-pack.js";

import NavTools from "@/components/NavTools.vue";
import apiClient from "@/utils/apiClient.js";
import ivbus from "@/utils/ivbus.js";
import Alert from "@/components/Alert.vue";
import Spinner from "@/components/Spinner.vue";

function makeid(length) {
  var result = "";
  //~ var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default {
  mixins: [ivAlertMixin, ivSpinnerMixin, ivUserAccountMixin],
  components: {
    NavTools,
    Alert,
    Spinner,
  },

  props: ["staticaddr", "serveraddr", "ameliaaddr", "lang", "lang_label"],

  computed: mapState(['apiDown']),

  data() {
    return {
      loggedin: false,

      loginpassword: "",
      loginemail: "",

      uiMessageWaitShort: 5000,
      uiMessageWaitLong: 10000,

      view: "login",
    };
  },

  mounted: function () {
    this.fetchUser();
  },

  methods: {
    user_logout: function () {
      var that = this;
      var spinId = that.spinStart();
      this.$http
        .get("/api/user/sign_out/")
        .then(function (response) {
          that.spinStop(spinId);
          sessionStorage.removeItem("apitoken");
          delete Vue.http.headers.common.Authorization;
          ivbus.setFlag("user", null);
        })
        .catch(function () {
          that.spinStop(spinId);
          sessionStorage.removeItem("apitoken");
          delete Vue.http.headers.common.Authorization;
          ivbus.setFlag("user", null);
        });
    },

    user_login: function () {
      var that = this;

      if (!localStorage.login_session && !localStorage.device_token) {
        localStorage.login_session = makeid(30);
      }

      var messageId = that.showMessage(
        "Logging in...",
        "alert-warning",
        that.uiMessageWaitLong
      );

      var spinId = that.spinStart();
      that.$http
        .post("/api/user/api-token-auth/", {
          username: that.loginemail,
          password: that.loginpassword,
          login_session: localStorage.login_session, // only one of these should be set at a time
          device_token: localStorage.device_token,
        })
        .then(function (response) {
          that.spinStop(spinId);
          that.hideMessage(messageId);
          if (response.data.status == 200) {
            if (response.data.token !== undefined) {
              sessionStorage.apitoken = response.data.token;
              that.fetchUser();
            } else {
              that.view = "check_for_lt";
              if (response.data.device_token) {
                localStorage.device_token = response.data.device_token;
                localStorage.removeItem("login_session");
              }
              that.pollLoginToken();
            }
          } else {
            localStorage.removeItem("login_session");
            localStorage.removeItem("device_token");
            that.showMessage(
              "Oops, that didn't work! Please refresh the page and try again. If the problem persists, contact us at contact@initialview.com for assistance!",
              "alert-danger",
              that.uiMessageWaitLong
            );
          }
        })
        .catch(function (response) {
          that.hideMessage(messageId);
          that.showMessage(
            "Invalid email or password.",
            "alert-danger",
            that.uiMessageWaitLong
          );
          that.spinStop(spinId);
          that.user_logout();
        });
    },

    user_forgot_password: function () {
      window.location.href = "/password/forgot";
    },

    pollLoginToken: function () {
      var that = this;
      var spinId = that.spinStart();
      that.$http
        .post("/api/user/poll_login_token/", {
          device_token: localStorage.device_token,
        })
        .then(function (response) {
          that.spinStop(spinId);
          if (response.data.status == 200) {
            if (response.data.poll) {
              window.setTimeout(function () {
                that.pollLoginToken();
              }, 5000);
            } else {
              that.user_login();
            }
          } else {
            localStorage.removeItem("login_session");
            localStorage.removeItem("device_token");
            that.showMessage(
              "Oops, that didn't work! Please refresh the page and try again. If the problem persists, contact us at contact@initialview.com for assistance!",
              "alert-danger",
              that.uiMessageWaitLong
            );
          }
        })
        .catch(function (response) {
          that.spinStop(spinId);
          localStorage.removeItem("login_session");
          localStorage.removeItem("device_token");
          that.showMessage(
            "Oops, that didn't work! Please refresh the page and try again. If the problem persists, contact us at contact@initialview.com for assistance!",
            "alert-danger",
            that.uiMessageWaitLong
          );
        });
    },

    fetchUser: function () {
      var that = this;

      // Special case where user came from initialview login page with pre-authorized persisted (LocalStorage) apitoken via iframe
      // For security: we don't want it in LocalStorage.
      // We need to remove the persisted apitoken and move it into temporary (SessionStorage).
      // To prevent additional re-directs, do this on the amelia homepage as well.
      if (localStorage.apitoken !== undefined) {
        sessionStorage.apitoken = localStorage.apitoken;
        localStorage.removeItem("apitoken");
      }

      if (sessionStorage.apitoken !== undefined) {
        apiClient.defaults.headers.common["Authorization"] =
          "Token " + sessionStorage.apitoken;

        // Store the token in cookie format for access lock-down
        document.cookie = "auth_token=" + sessionStorage.apitoken + "; path=/";

        var spinId = that.spinStart();
        apiClient
          .get("/api/user/user_info/")
          .then(function (response) {
            if (
              response.data.status === false ||
              response.data.status === 401
            ) {
              that.spinStop(spinId);
              that.user_logout();
            } else {
              // don't stop the spinner if we're going to redirect away from the page

              // if hostnames don't match, go to data.r
              var currentHost =
                window.location.protocol + "//" + window.location.hostname;
              var dataR = response.data.r;
              if (dataR.indexOf(currentHost) == -1) {
                window.location = dataR;
              } else {
                // redirect to rParam if it exists, else go to data.r
                var rParam = getParameterByName("r");
                if (rParam) {
                  var paramR = (window.location = getParameterByName("r"));
                  var params = window.location.search.substr(1);
                  var paramsNoR = params.replace(
                    /&r(\=[^&]*)?(?=&|$)|^r(\=[^&]*)?(&|$)/,
                    ""
                  );
                  window.location =
                    paramR + "?" + paramsNoR + window.location.hash;
                } else {
                  window.location = dataR;
                }
              }
            }
          })
          .catch(function () {
            that.spinStop(spinId);
          });
      } else {
        that.user_logout();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
